import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { navigate } from "gatsby";
import Layout from "../components/Layout/Layout";
import DashboardTabs from "../components/DashboardTabs/DashboardTabs";
import DashboardApplication from "../components/DashboardApplication/DashboardApplication";
import AccountSettings from "../components/AccountSettings/AccountSettings";
import Community from "../components/Community/Community";
import CoursesList from "../components/CoursesList/CoursesList";
import EnrollmentNotification from "../components/CourseDetail/child-components/EnrollmentNotification";
import useEnrollmentNotificationData from "../hooks/enrollment-notification-data";
import useApplicationNotificationData from "../hooks/application-notification-data";
// import useFellowshipDashboardData from "../hooks/fellowship-dashboard";
import LanguageToggle from "../components/shared/LanguageToggle/LanguageToggle";
import {
  setAllCourses,
  setSearchCourses,
  setupCourses,
} from "../state/actions";
import {
  _post,
  _get,
  _put,
  _delete,
  _communityGet,
} from "../utils/services/api.service";
import {
  isUserType,
  gotoSurveyMonkey,
  removeUserDetails,
  getUserDetails,
  saveUserDetails,
} from "../utils/services/methods";

const logoutUrl = `${process.env.GATSBY_login_url}sso/logout`;

const Dashboard = ({ data, dispatch, pageContext: { locale }, location }) => {
  const enrollmentNotificationData = useEnrollmentNotificationData(),
    applicationNotificationData = useApplicationNotificationData(),
    clearPendingCourseDetailsAndEnrollmentNotifications = (sso = false) => {
      setLoading(true);
      localStorage.removeItem("pendingCourseEnrollmentDetails");
      setNotification(null);
      if (sso) {
        gotoSurveyMonkey();
      } else {
        setLoading(false);
      }
    };

  const { search = {}, state = {} } = location,
    pendingCourseEnrollmentNotification = state
      ? state.pendingCourseEnrollmentNotification || null
      : null,
    activeTab = search.substr(1) || "courses",
    [showAllListViewCourses, setShowAllListViewCourses] = useState(false),
    dashboardData = data?.dashboard?.nodes[0],
    [gridMenu, setGridMenu] = useState(true),
    [listMenu, setListMenu] = useState(false),
    [notification, setNotification] = useState(
      pendingCourseEnrollmentNotification
    ),
    [order, setOrder] = useState(false),
    [courses, setCourses] = useState([]),
    [applications, setApplications] = useState([]),
    [userAccountDetails, setUserAccountDetails] = useState(null),
    [loading, setLoading] = useState(false),
    [formError, setFormError] = useState(null),
    [firstNameError, setFirstNameError] = useState(null),
    [lastNameError, setLastNameError] = useState(null),
    [emailError, setEmailError] = useState(null),
    [oldPasswordError, setOldPasswordError] = useState(null),
    [passwordError, setPasswordError] = useState(null),
    [confirmPasswordError, setConfirmPasswordError] = useState(null),
    [accountDetailsFormIsEditing, setAccountDetailsFormIsEditing] = useState(
      false
    ),
    [fellowshipCourses, setFellowshipCourses] = useState([]),
    [pastCommunityEvents, setPastCommunityEvents] = useState([]),
    [upcomingCommunityEvents, setUpcomingCommunityEvents] = useState([]),
    handleToggleShowAllCourses = bool => setShowAllListViewCourses(bool),
    determineErrorAction = errorArray => {
      errorArray.map(error => {
        switch (error.key) {
          case "first_name":
            return setFirstNameError(error.message);
          case "last_name":
            return setLastNameError(error.message);
          case "email":
            return setEmailError(error.message);
          case "old_password":
            return setOldPasswordError(error.message);
          case "password":
          case "password is required":
            return setPasswordError(error.message);
          case "confirm_password":
            return setConfirmPasswordError(error.message);
          default:
            setFormError(error.message);
        }
      });
    },
    logout = async () => {
      setLoading(true);
      const deleteRequest = await _delete({
        endpoint: `profile/${userAccountDetails.external_id}`,
      });
      if (deleteRequest.success) {
        removeUserDetails();
        window.location.replace(logoutUrl);
      } else {
        setFormError(deleteRequest.errors[0].message);
        setLoading(false);
      }
    },
    userDetails = getUserDetails(),
    isFoundry = userDetails?.user_type?.includes("Foundry"),
    resetFormErrors = () => {
      setFirstNameError(null);
      setLastNameError(null);
      setEmailError(null);
      setPasswordError(null);
      setConfirmPasswordError(null);
      setOldPasswordError(null);
      setFormError(null);
    },
    updateUserDetailsApiCall = async formData => {
      setLoading(true);
      const updateUserResponse = await _put({
        isPublic: true,
        endpoint: `profile`,
        args: { account: formData },
      });
      if (!updateUserResponse.success) {
        determineErrorAction(updateUserResponse.errors);
      } else {
        setUserAccountDetails({ ...updateUserResponse.data.user });
        setAccountDetailsFormIsEditing(false);
        //update details on cookies
        updateCookiesData();
      }
      setLoading(false);
    },
    updateUserSubscription = async formData => {
      setLoading(true);
      const updateSubscriptionResponse = await _put({
        isPublic: true,
        endpoint: `profile/newsletter`,
        args: { ...formData },
      });
      if (updateSubscriptionResponse.success) {
        const updatedUserDetails = await _get({
          endpoint: `profile/${formData.external_id}`,
        });
        setUserAccountDetails({ ...updatedUserDetails.data.user });
      }
      setLoading(false);
    },
    updateUserDetails = (ev, data) => {
      const isNewsletterUpdate = data.newsletterUpdate || false;
      Object.keys(data).forEach(key => {
        if (!data[key].length) {
          if (key === "first_name" || key === "last_name") {
            data[key] =
              key === "first_name"
                ? userAccountDetails.first_name
                : userAccountDetails.last_name;
          } else if (key !== "mailing_list") delete data[key];
        }
      });
      const formattedData = {
        ...data,
        external_id: userAccountDetails.external_id,
      };
      if (userAccountDetails.provider_id)
        formattedData.provider_id = userAccountDetails.provider_id;
      if (isNewsletterUpdate) {
        updateUserSubscription(formattedData);
      } else {
        updateUserDetailsApiCall(formattedData);
      }
    },
    updateCookiesData = () => {
      let userDetails = getUserDetails();
      const {
        email,
        first_name,
        last_name,
        provider_id,
        user_type,
      } = userAccountDetails;
      userDetails.email = email;
      userDetails.first_name = first_name;
      userDetails.last_name = last_name;
      userDetails.provider_id = provider_id;
      userDetails.user_type = user_type;
      saveUserDetails(userDetails);
    };
  useEffect(() => {
    setLoading(true);
    if (!userDetails) {
      navigate("/sso/");
    }
    const isFellow = userDetails ? isUserType("Fellow", userDetails) : false;
    const handlePendingApplication = async (
        userDetails,
        pendingApplicationDetails
      ) => {
        await callAcumenApi(userDetails, pendingApplicationDetails);
      },
      callAcumenApi = async (userDetails, pendingApplicationDetails) => {
        const { productId } = JSON.parse(pendingApplicationDetails),
          args = { email: userDetails.email, product_id: productId };
        setLoading(true);
        const response = await _post({
          endpoint: `applications`,
          args,
        });
        if (response.success) {
          gotoSurveyMonkey();
        } else {
          setNotification({
            status: "fail",
            type: "application",
          });
        }
        getDashboardData();
      };
    const handlePendingCourseEnrollment = async (
        userDetails,
        pendingCourseDetails
      ) => {
        const parsedCourseDetails = JSON.parse(pendingCourseDetails),
          {
            courseType,
            courseId,
            provider,
            providerUrl,
            courseTheme,
            title,
            courseCost,
            courseAvailability,
            courseDuration,
            slug,
            flexible_amount,
          } = parsedCourseDetails,
          {
            external_id,
            email,
            first_name,
            last_name,
            provider_id,
          } = userDetails;

        localStorage.removeItem("pendingCourseEnrollmentDetails");
        // If paid, hook into payment workflow.

        let cost = courseCost.replace("$", "");
        if (
          (!isNaN(cost) && Number(flexible_amount) != 0) ||
          (flexible_amount && Number(flexible_amount) != 0)
        ) {
          const stateObject = {
            courseType,
            courseId,
            title,
            provider,
            providerUrl,
            courseTheme,
            courseCost,
            courseAvailability,
            courseDuration,
            slug,
            flexible_amount,
          };

          return navigate("/payment/", {
            state: {
              course: stateObject,
            },
          });
        }

        const argsObject = {
          external_id,
          email,
          first_name,
          provider_url: providerUrl,
          last_name,
          course_id: courseId,
          provider,
          course_name: title,
        };

        if (provider_id !== "") argsObject.provider_id = provider_id;
        if (
          "flexible_amount" in parsedCourseDetails &&
          Number(parsedCourseDetails.flexible_amount) != 0
        )
          argsObject.flexible_amount = parsedCourseDetails.flexible_amount;

        const courseEnrollment = await _post({
          endpoint: "enrollments",
          args: argsObject,
        });

        if (
          courseEnrollment.success &&
          provider_id === "" &&
          provider.toUpperCase() === "THINKIFIC"
        ) {
          saveUserDetails({
            external_id,
            email,
            first_name,
            last_name,
            provider_id: courseEnrollment.data.user.provider_id,
          });
          // A future release of Chrome will only deliver cookies with cross-site requests if they are set with `SameSite=None` and `Secure`. - 1/15/20
        }
        // set enrollment notification to display in the once they navigate to the dashboard

        if (courseEnrollment.success) {
          setNotification({
            status: "success",
            type: "course",
          });
        } else {
          setNotification({
            status: "fail",
            type: "course",
          });
        }
        getDashboardData();
      },
      checkEnrollmentStatus = async (
        userDetails,
        pendingCourseEnrollmentDetails
      ) => {
        // Check if user is enrolled already or not
        const enrollment = await _get({
          endpoint: `enrollments/status?external_id=${
            userDetails.external_id
          }&provider_url=${
            JSON.parse(pendingCourseEnrollmentDetails).providerUrl
          }`,
        });
        if (enrollment.success) {
          if (enrollment.data.message === "User has not enrolled") {
            await handlePendingCourseEnrollment(
              userDetails,
              pendingCourseEnrollmentDetails
            );
          } else {
            getDashboardData();
          }
        } else {
          await handlePendingCourseEnrollment(
            userDetails,
            pendingCourseEnrollmentDetails
          );
        }
      },
      getApplications = async () => {
        let applications = await _get({
          endpoint: `applications/${
            userDetails ? userDetails.external_id : ""
          }`,
        });
        setApplications(applications.data.applications || []);
      },
      getApplicationStatus = async (userDetails, pendingApplicationDetails) => {
        const { productId } = JSON.parse(pendingApplicationDetails),
          enrollment = await _get({
            endpoint: `enrollments/status?external_id=${userDetails.external_id}&provider_url=${productId}&type=Application`,
          });
        if (enrollment.success) {
          if (enrollment.data.message !== "User has enrolled") {
            handlePendingApplication(userDetails, pendingApplicationDetails);
          } else {
            getDashboardData();
          }
        } else {
          getDashboardData();
        }
      },
      getCourses = async () => {
        let userCourses = await _post({
          endpoint: "courses",
          args: {
            method: "get_courses",
            email: userDetails ? userDetails.email : "",
            external_id: userDetails ? userDetails.external_id : "",
            page: 0,
            filters: {
              status: ["all"],
              availability: ["all"],
              formats: ["all"],
              topics: ["all"],
            },
          },
        });
        if (isFellow) {
          let fellowshipCoursesResponse = await _post({
            endpoint: "courses",
            args: {
              method: "get_courses",
              type: "Fellowship",
              email: userDetails ? userDetails.email : "",
              external_id: userDetails ? userDetails.external_id : "",
              page: 0,
              filters: {
                status: ["all"],
                availability: ["all"],
                formats: ["all"],
                topics: ["all"],
              },
            },
          });
          if (fellowshipCoursesResponse.success)
            setFellowshipCourses(fellowshipCoursesResponse.data.courses);
        }
        dispatch(setAllCourses(userCourses.data.courses || []));
        dispatch(setupCourses(userCourses.data.courses || []));
        dispatch(setSearchCourses());
      },
      fetchUserDetails = async () => {
        let userAccountInfo = await _get({
          endpoint: `profile/${userDetails.external_id}`,
        });
        userAccountInfo.success
          ? setUserAccountDetails(userAccountInfo.data.user)
          : determineErrorAction(userAccountInfo.errors);
        setLoading(false);
      },
      getCommunityEvents = async () => {
        let events = await _communityGet({
          endpoint: `events`,
        });
        if (events.success) {
          setPastCommunityEvents(events.data.past_events);
          setUpcomingCommunityEvents(events.data.upcoming_events);
        }
      };

    const pendingCourseEnrollmentDetails = localStorage.getItem(
        "pendingCourseEnrollmentDetails"
      ),
      pendingApplicationDetails = localStorage.getItem(
        "pendingApplicationDetails"
      ),
      getDashboardData = () => {
        getCourses();
        getApplications();
        setUserAccountDetails(userDetails);
        getCommunityEvents();
        fetchUserDetails();
      };

    if (pendingCourseEnrollmentDetails || pendingApplicationDetails) {
      setLoading(true);
      if (pendingCourseEnrollmentDetails) {
        checkEnrollmentStatus(userDetails, pendingCourseEnrollmentDetails);
      } else if (pendingApplicationDetails) {
        navigate("/fellowship/apply");
      }
    } else {
      getDashboardData();
    }
  }, []);

  return (
    <Layout
      loading={loading}
      enforceWidth={true}
      marketingModal={dashboardData?.marketingModal}
      locale={locale}
      isFoundry={isFoundry}
    >
      {notification ? (
        <EnrollmentNotification
          notification={notification}
          notificationData={
            notification.type === "course"
              ? enrollmentNotificationData
              : applicationNotificationData
          }
          clearPending={clearPendingCourseDetailsAndEnrollmentNotifications}
        />
      ) : (
        <DashboardTabs
          locale={locale}
          activeTab={activeTab}
          userType={
            userDetails && userDetails.user_type ? userDetails.user_type : []
          }
          account={
            userDetails
              ? {
                  email: userDetails.email,
                  first_name: userDetails.first_name,
                }
              : {}
          }
          accountSettingsInfo={data?.accountSettingsData?.nodes[0]}
        >
          <div label={locale === "en-US" ? "Courses" : "Cursos"} key="courses">
            <LanguageToggle
              toggleText={dashboardData?.contentModules[16].listItems}
            />
            <CoursesList
              gridMenu={gridMenu}
              setGridMenu={setGridMenu}
              listMenu={listMenu}
              setListMenu={setListMenu}
              handleToggleShowAllCourses={handleToggleShowAllCourses}
              blogSlides={dashboardData?.contentModules[9]}
              order={order}
              setOrder={setOrder}
              locale={locale}
              dashboardData={dashboardData?.contentModules}
            />
          </div>
          <div
            label={locale === "en-US" ? "Applications" : "Aplicaciones"}
            key="applications"
          >
            <LanguageToggle
              toggleText={dashboardData?.contentModules[16].listItems}
            />
            <DashboardApplication
              applications={applications}
              isEvaluator={
                userDetails ? isUserType("Evaluator", userDetails) : false
              }
              applicationData={data?.applications?.nodes[0]}
            />
          </div>
          <div
            label={
              isFoundry
                ? "Foundry"
                : locale === "en-US"
                ? "Community"
                : "Comunidad"
            }
            key={isFoundry ? "foundry" : "community"}
          >
            <LanguageToggle
              toggleText={dashboardData?.contentModules[16].listItems}
            />
            <Community
              userDetails={userDetails ? userDetails : null}
              pastEvents={pastCommunityEvents}
              upcomingEvents={upcomingCommunityEvents}
              communityDashboardData={data?.communityDashboardData?.nodes[0]}
              locale={locale}
            />
          </div>
          <div
            label={
              locale === "en-US"
                ? "Account Settings"
                : "Configuraciones de la cuenta"
            }
            key="account-settings"
          >
            <LanguageToggle
              toggleText={dashboardData?.contentModules[16].listItems}
            />
            <AccountSettings
              {...userAccountDetails}
              isFoundry={isFoundry}
              deleteAccount={async () => {
                await logout();
              }}
              formError={formError}
              firstNameError={firstNameError}
              lastNameError={lastNameError}
              emailError={emailError}
              oldPasswordError={oldPasswordError}
              passwordError={passwordError}
              confirmPasswordError={confirmPasswordError}
              updateUserDetails={updateUserDetails}
              accountDetailsFormIsEditing={accountDetailsFormIsEditing}
              setAccountDetailsFormIsEditing={setAccountDetailsFormIsEditing}
              resetFormErrors={resetFormErrors}
              accountSettingsInfo={data?.accountSettingsData?.nodes[0]}
            />
          </div>
        </DashboardTabs>
      )}
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    dashboard: allContentfulPageLayout(
      filter: {
        queryIdentifier: { eq: "Dashboard 2.0" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        contentModules {
          ... on ContentfulTitleDescription {
            title
            description {
              description
            }
            options
            link {
              linkText
              url
            }
          }
          ... on ContentfulTitleAndList {
            listItems
          }
          ... on ContentfulLink {
            linkText
            url
          }
          ... on ContentfulCarouselContent {
            heading
            cta {
              linkText
              url
            }
            carouselItems {
              ... on ContentfulCourseDetailPage {
                theme: theme20 {
                  theme
                  themeNumber
                }
                title
                courseCost
                courseDuration
                courseAvailability
                courseId
                slug
                productType: courseType
                courseImage {
                  fluid {
                    srcSet
                    aspectRatio
                  }
                }
              }
            }
          }
          ... on ContentfulUpcomingCoursesFeaturedBlogs {
            heading
            courses {
              ... on ContentfulBlogDetailPage {
                theme {
                  theme
                  themeNumber
                }
                title
                urlCtaText
                url
                blogImage {
                  fluid {
                    aspectRatio
                    srcSet
                  }
                  description
                }
              }
            }
            linkText
            url
          }
        }
        marketingModal {
          ... on ContentfulMarketingModal {
            id
            cta {
              linkText
              url
            }
            description {
              description
            }
            title
            showAfterPercent
            hubspotListId
            marketingModalImage {
              image {
                fluid {
                  aspectRatio
                  srcSet
                }
              }
            }
            formFields
            successTitle
            successDescription
            additionalText {
              json
            }
          }
        }
      }
    }
    applications: allContentfulPageLayout(
      filter: {
        node_locale: { eq: $locale }
        queryIdentifier: { eq: "dashboard-applications-no-applications" }
      }
    ) {
      nodes {
        heading
        contentModules {
          ... on ContentfulTitleDescription {
            title
            description {
              description
            }
            link {
              linkText
              url
            }
          }
        }
      }
    }
    communityDashboardData: allContentfulCommunityPageLayout(
      filter: {
        queryIdentifier: { eq: "community-dashboard" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        heroVideoCommunityMember {
          source
          url
        }
        heroVideoCommunityNonMember {
          source
          url
        }
        heroVideoThumbnailCommunityMember {
          fluid {
            srcSet
            aspectRatio
          }
        }
        heroVideoThumbnailCommunityNonMember {
          fluid {
            srcSet
            aspectRatio
          }
          file {
            url
          }
        }
        heroLinks {
          sectionIdentifier
          linkText
          url
        }
        heading
        headingFoundry
        foundryStoriesHeader
        foundryStoriesSectionLink {
          linkText
          url
        }
        foundryStoriesSlider {
          foundryMember
          title
          readMoreLink {
            linkText
            url
          }
          image {
            fluid {
              srcSet
            }
          }
          videoPreviewUrl
          videoThumbnail {
            fluid {
              srcSet
            }
          }
        }
        foundryTeamHeading
        foundryTeamDescription {
          foundryTeamDescription
        }
        foundryTeamMembers {
          name
          sectionTitle
          photoLogo {
            fluid {
              src
            }
          }
          cta {
            linkText
            url
          }
        }
        foundryConversations {
          title
          description {
            description
          }
          image {
            fluid {
              srcSet
            }
          }
          link {
            linkText
            url
          }
        }
        contentModules {
          ... on ContentfulTitleDescription {
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            title
          }
          ... on ContentfulBenefitsCard {
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            benefit1
            description1
            icon1Image {
              fluid {
                aspectRatio
                srcSet
              }
            }
            cta1 {
              linkText
              url
            }
            benefit2
            description2
            icon2Image {
              fluid {
                aspectRatio
                srcSet
              }
            }
            cta2 {
              linkText
              url
            }
            benefit3
            description3
            icon3Image {
              fluid {
                aspectRatio
                srcSet
              }
            }
            cta3 {
              linkText
              url
            }
            benefit4
            description4
            icon4Image {
              fluid {
                aspectRatio
                srcSet
              }
            }
            cta4 {
              linkText
              url
            }
          }
          ... on ContentfulRichTextModule {
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            richText {
              richText
            }
          }
          ... on ContentfulUpcomingCoursesFeaturedBlogs {
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            title
            courses {
              ... on ContentfulBlogDetailPage {
                blogImage {
                  fluid {
                    aspectRatio
                    srcSet
                  }
                }
                title
                url
                theme {
                  theme
                  themeNumber
                }
              }
            }
          }
        }
      }
    }
    accountSettingsData: allContentfulPageLayout(
      filter: {
        queryIdentifier: { eq: "Account Settings" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        heading
        contentModules {
          ... on ContentfulLink {
            heading
            linkText
            url
          }
          ... on ContentfulNameImageDetails {
            name
            sectionTitle
            titleAndDescription {
              title
              sectionIdentifier
              description {
                description
              }
            }
            list
            cta {
              linkText
            }
          }
        }
      }
    }
  }
`;

export default connect()(Dashboard);
